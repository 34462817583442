.static-view {
	margin-top: var(--page-margin-top);
}

.privacy-v1 {
	ul {
		list-style: disc;
		margin: 15px 30px;
		padding: 5px;
	}
}

.voucher-v1 {
	margin-top: var(--page-margin-top);
}

.static-v1 {
	margin-top: var(--page-margin-top);

	p {
		a {
			font-weight: 700;
		}
	}

	ul {
		li {
			a {
				font-weight: 700;
			}
		}
	}


}

.inner-banner {

	&.faq {
		&.full-view {
			.page-title {
				bottom: 5%;
				top: inherit;
			}

		}
	}

	&.full-view {
		height: var(--index-slide-show-height);

		&.zugspitz {
			.page-title {
				top: 45%;
			}
		}

		&.farchant-banner {
			.page-title {
				top: 27%;
			}
		}


		&.bergblick {
			background-image: url(RESOURCE/img/inner-banner-bergblick.jpg);
			background-position: center bottom;

			.page-title {
				top: 37%;
			}
		}

		&.mitHund {
			background-image: url(RESOURCE/img/inner-banner-urlaub-mit-hund.jpg);
			background-position: center top;

			.page-title {
				top: 110px;
				transform: translate(-50%, 0);
			}
		}

		&:after {
			display: none;
		}



		&.angebote {
			background-image: url(RESOURCE/img/banner-angebote.jpg);

			.page-title {
				top: 55px;
				transform: translate(-50%, 0);
			}
		}

		&.alpenferien {
			background-image: url(RESOURCE/img/inner-banner-nachhaltige.jpg);
			background-position: center bottom;

			.page-title {
				top: 58%;
			}
		}

		&.nachhaltigkeit {
			background-image: url(RESOURCE/img/banner-nachhaltigkeit.jpg);

			.page-title {
				top: 105px;
				transform: translate(-50%, 0);
			}
		}

		.page-title {
			left: 50%;
			text-align: center;
			transform: translate(-50%, -50%);
			top: 50%;
			bottom: inherit;
		}

		.pag-title-center {
			display: inline-block;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 0;
			padding: 15px;

			h1 {
				font-size: 80px;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);
				line-height: 70px;
				font-weight: 400;
				margin: 0;
				padding: 0 15px;
				font-family: 'Turbinado Pro';

				@media (min-width: 1600px) {
					/*font-size: 80px;*/
					margin: 0;
				}

				@media (max-width: 1199px) {
					font-size: 60px;
					line-height: 50px;
				}

				@media (max-width: 992px) {
					font-size: 60px;

				}

				@media (max-width: 767px) {
					font-size: 34px;
					line-height: 25px;

				}

				@media (max-height: 550px) {
					font-size: 36px;
					line-height: normal;
				}
			}

			span {
				color: #fff;
				font-size: 26px;
				text-shadow: rgb(0 0 0 / 50%) 0 0.1rem 1rem;
				font-weight: 300;
				padding: 0 15px;
				line-height: 30px;
				display: inline-block;

				@media (max-width: 992px) {
					font-size: 20px;
					line-height: normal;
				}

				@media (max-width: 767px) {
					font-size: 16px;
					line-height: normal;
				}

				@media (max-height: 550px) {
					font-size: 18px;
					line-height: normal;
				}
			}
		}
	}

	&.alpenferienwohnungen {
		background-image: url(RESOURCE/img/alpenferienwohnungen-banner01.jpg);
	}

	&.farchant-banner {
		background-image: url(RESOURCE/img/banner-farchant.webp);
	}

	&.garmisch-banner {
		background-image: url(RESOURCE/img/banner-garmisch-partenkirchen.webp);
	}

	/*	&.mitHund {
		background-image: url(RESOURCE/img/Urlaub-mit-Hund.jpg);
	}*/

	&.vermieter {
		/*background-image: url(RESOURCE/img/vermieter_banner.png);*/
		background-image: url(RESOURCE/img/banner-immobilien.webp);
		/*min-height: 80vh;*/
	}

	&.nachhaltigkeit {
		background-image: url(RESOURCE/img/nachhaltigkeit_hero.jpg);
	}

	&.zugspitz {
		background-image: url(RESOURCE/img/banner_zugspitz-region.webp);
	}

	&.faq {
		background-image: url(RESOURCE/img/banner-faq.webp);
	}

	.banner-heading {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		margin: 0 auto;

		h2 {
			font-family: 'Turbinado Pro';
			font-size: 90px;
			color: var(--color-white);
			font-weight: 700;
			text-align: center;
			margin: 0;

			@media (max-width:767px) {
				font-size: 50px;
				line-height: 50px;

			}
		}

		span {
			font-family: 'Open Sans';
			color: var(--color-white);
			font-size: 22px;
			margin-bottom: 25px;

			@media (max-width:767px) {
				text-align: center;

			}
		}

		.btn {
			min-width: 200px;
		}
	}

	&.gutschein {
		background-image: url(RESOURCE/img/gutschein-banner.jpg);

		.page-title {
			bottom: 50px;
			top: auto;
			transform: translate(-50%, 0);
		}
	}
}


.farchant-v1,
.garmisch-v1 {
	img {
		width: 100%;
	}

}

.alpenferienwohnungen-v1 {
	img {
		width: 100%;
		object-fit: cover;
		height: calc(100% - 30px);
	}

	/*.unit-card-v2 {

		.unit-icon-rating,
		.comodities {
			display: none;
		}

		.content {
			padding: 20px 10px 10px;
		}
	}*/
}

.sehenswurdigkeiten-v1 {
	.list-group {
		text-align: left;
		display: flex;
		flex-direction: unset;
		flex-wrap: wrap;

		@media (max-width: 767px) {
			padding: 0 5px !important;
		}

		.list-group-item {
			display: inline-block;
			width: 33.33%;
			padding: 0 15px;
			border-radius: 0;
			background: transparent;
			border: none;
			margin-bottom: 30px;

			@media (max-width: 767px) {
				width: 100%;
				border: 1px solid rgba(0, 0, 0, .125);
				padding: 15px;
				border-radius: 7px;
				background: #fff;
				margin-bottom: 15px;
			}

			.leading-image-container {
				margin: 0 0 10px 0 !important;
				width: 100% !important;
				height: 180px !important;

				@media all and (min-width: 768px) and (max-width: 1199px) {
					height: 150px !important;
				}

				.leading-image {
					height: 180px !important;

					@media all and (min-width: 768px) and (max-width: 1199px) {
						height: 150px !important;
					}
				}
			}

		}

		.list-group-item:hover>.d-flex {

			@media (min-width: 768px) {

				background-color: #f4f4f4;
			}
		}

		.list-group-item>.d-flex {
			@media (min-width: 768px) {
				border: 1px solid rgba(0, 0, 0, 0.125);
				padding: 15px;
				border-radius: 7px;
				background: #fff;
				margin-bottom: 0;
				height: 100%;
				flex-direction: column !important;
				transition: 0.3s;
				justify-content: flex-start;
			}
		}
	}
}

.gutschein-form {
	.checkbox {
		label {
			margin-bottom: 5px;
		}
	}
}