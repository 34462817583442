.search-unit-v3 {
	.unit-type-place {
		display: flex;
		justify-content: space-between;
	}

	.unit-item {
		background-color: #fff;
		padding: 0;
		border: 0px solid #fff;
		transition: all 0.2s;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		box-shadow: 0 0 22px -6px rgb(0 0 0 / 5%);

		&:hover {
			border: 0px solid var(--color-primary);


			.owl-carousel {
				.owl-nav {
					button {
						visibility: visible;
						opacity: 1;
					}
				}
			}

			.go-to-unit {
				opacity: 1;
			}
		}

		.owl-carousel {
			overflow: hidden;

			.owl-nav {
				button {
					width: 30px;
					height: 30px;
					top: 107px;
					position: absolute;
					border-radius: 50%;
					cursor: pointer;
					visibility: hidden;
					opacity: 0;
					transition: visibility 0s, opacity 0.5s linear;
					color: var(--color-black);
					font-size: 40px;
					line-height: 1px;
					text-align: center;
					background-color: rgba(255, 255, 255, 0.7);
				}

				.owl-prev {
					left: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-right: 1px;
					}
				}

				.owl-next {
					right: 10px;

					span {
						display: inline-block;
						padding-bottom: 6px;
						padding-left: 1px;
					}
				}
			}

			.owl-dots {
				position: absolute;
				width: 70%;
				bottom: 10px;
				padding: 0;
				margin: 0;
				list-style: none;
				text-align: center;
				line-height: 1;
				left: 50%;
				transform: translateX(-50%);

				.owl-dot {
					background-color: #fff;
					width: 6px !important;
					height: 6px !important;
					opacity: .25;
					margin: 0 1px !important;
					border-radius: 50%;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		.unit-container {
			display: flex;
			flex-direction: column;
			height: 100%;

			.icons-rating-bl {
				height: auto;
				position: relative;
				margin: -25px 20px 0;
				padding: 10px;
				background-color: #fff;
				border-radius: 5px;
				box-shadow: 0 0 10px 0 rgb(6 30 98 / 8%);
				display: flex;
				justify-content: space-between;
				align-items: center;
				z-index: 1;

				@media (max-width:550px) {
					margin-left: 0;
					margin-right: 0;
				}

				.rating {
					font-size: 12px;

					.fa {
						color: var(--color-secondary);
					}
				}
			}

			.content-col {
				padding-left: 15px;
				padding-right: 15px;

				.icons-rating-bl {
					.rating {
						display: flex;
						margin-bottom: 0;

						.fa {
							color: var(--color-orange);

							&.fa-star-o {
								display: none;
							}
						}
					}
				}

				.rating {
					margin-bottom: 10px;

					>div {
						display: flex;
						align-items: center;
					}

					.ratings {
						display: flex;
						align-items: center;
						margin-right: 5px;
					}

					.fa,
					.fas {
						color: var(--color-dark-blue);
					}
				}
			}

			.img-col {
				position: relative;
				width: 100%;
				padding-top: 56.25%;

				.fav-icon {
					position: absolute;
					left: 10px;
					top: 10px;
					color: #fff;
					cursor: pointer;
					z-index: 2;
				}



				.rating {
					position: absolute;
					right: 0px;
					bottom: 0px;
					padding: 7px 7px 7px 12px;
					z-index: 2;
					background-color: rgba(255, 255, 255, 0.7);
					font-size: var(--font-size-md);
					font-size: 14px;

					.fa {
						color: var(--color-secondary);
					}

					.ratings {
						display: inline-block;
						margin-left: 3px;
					}

				}

				.price {
					position: absolute;
					background-color: var(--color-secondary);
					text-align: center;
					top: 20px;
					right: 0;
					padding: 0px 10px;
					z-index: 2;
					color: #fff;
					border-radius: 5px 0 0 5px;

					.h3 {
						font-size: 18px;
						color: #fff;
						line-height: 28px;
					}

					.main-price {
						&.h5 {
							color: var(--color-grey-light);
							text-decoration: line-through;

						}
					}


				}

				img {
					width: 100%;
					object-fit: cover;
					height: 100%;
					object-position: center center;
				}

				.img-content {

					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					overflow: hidden;

				}

			}

			.unit-title {
				padding-top: 10px;

				.h3 {
					font-size: var(--font-size-main);
				}
			}

			.type {
				font-size: var(--font-size-md);
			}

			.place {
				font-size: var(--font-size-md);

				.fa {
					color: var(--color-grey-dark);
				}
			}

			.unit-highlights {
				text-align: right;
				display: flex !important;

				>i {
					width: 30px;
					height: 30px;
					position: relative;
					margin-right: 5px;
					background-color: #516970;
					background-color: var(--color-primary);
					display: -webkit-box;
					display: flex;
					-webkit-box-pack: center;
					justify-content: center;
					-webkit-box-align: center;
					align-items: center;
					color: #fff;
					font-size: 16px;
					background-size: 18px;

					@media (max-width:1480px) {
						width: 22px;
						height: 22px;
						margin-right: 3px;
						font-size: 14px;
						background-size: 14px
					}

					@media (max-width:767px) {
						width: 24px;
						height: 24px;
						margin-right: 3px;
						font-size: 15px;
						background-size: 15px;
					}

					&.fa-leaf {
						background-color: var(--color-secondary);
					}

					&.fa-charging-station {
						background-color: var(--color-secondary);
					}

				}

				.fa-stack {
					/* height: 33px !important;
					margin-left: -5px;
					width: 24px !important; */
				}
			}

			.flex-middle {
				flex-grow: 1;
				padding: 10px 0px;
			}

			.properties-row {

				display: flex;

				.unit-properties {
					width: 50%;
					font-size: var(--font-size-md);
				}
			}

			.alternatives {
				padding-top: 20px;

				.altheading {
					font-weight: 700;
					font-size: var(--font-size-md);
				}

				a {
					font-size: var(--font-size-md);
					text-decoration: none;
					color: var(--color-secondary);

					&:hover {
						text-decoration: underline;
					}
				}

				.fa {
					font-size: var(--font-size-sm);
					color: var(--color-secondary);
				}
			}

		}

		.link-to-redirect {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			button.btn-lg {
				border: none;
				background: transparent;
				padding: 0;
				font-size: 16px;
				color: var(--color-primary);

				&:hover {
					color: var(--color-secondary);
				}
			}

			i {
				color: var(--color-secondary);
				font-size: 14px;
				margin: 6px 0 0px 10px;
			}
		}

		.go-to-unit {
			height: 30px;
			display: inline-block;
			width: 30px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			background-color: var(--color-primary);
			transition: all 0.5s;
			opacity: 0;
			margin: -15px 0 10px 0;

			.fa {
				color: var(--color-white);
				font-size: 20px;
				line-height: 30px;
			}

		}


		.offer-box {
			position: absolute;
			top: 5px;
			right: 5px;
			font-weight: 700;
			z-index: 5;

			.offer-img {
				height: 35px !important;
				width: 35px !important;
			}

		}
	}

	.unit-box-links {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: row-reverse;
	}

}

.popover {
	border-radius: 0;
	background-color: var(--color-white);
	border: 1px solid #C0C0C0;
	font-size: var(--font-size-md);
	box-shadow: inherit;
	width: 100%;
	max-width: 520px;

	.popover-header {
		border-radius: 0;
		background-color: var(--color-bg-light);
		margin-bottom: 10px;
		padding: 10px 10px;
		font-size: var(--font-size-md);
		font-weight: normal;
	}

	.arrow {
		&:after {
			border-top-color: #C0C0C0 !important;
		}
	}

	.popover-body {
		padding: 10px;


		ul {
			overflow-y: scroll;
			height: 180px;
			padding-right: 10px;


			li {
				position: relative;
				margin-bottom: 2px;
				padding-left: 14px;
				list-style: none;
				font-size: var(--font-size-md);
				font-family: var(--font-family-main);
				margin-bottom: 3px;

				&:before {
					background-color: var(--color-primary);
					width: 5px;
					height: 5px;
					position: absolute;
					content: "";
					left: 0;
					top: 6px;
					border-radius: 50%;

				}
			}
		}
	}
}


.tooltip-inner {
	background-color: #acc0b2;
	color: #ffffff;
}

.tooltip.bs-tooltip-top .arrow:before {
	border-top-color: #acc0b2;
}

.tooltip.bs-tooltip-bottom .arrow:before {
	border-bottom-color: #acc0b2;
}

.tooltip.bs-tooltip-right .arrow:before {
	border-right-color: #acc0b2;
}

.tooltip.bs-tooltip-left .arrow:before {
	border-left-color: #acc0b2;
}